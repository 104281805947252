<template>
  <div>
    <Banner title="帮助中心" hint="全面解决客户遇到的各种问题，保证售后无忧">
      <img class="banner-bg" style="width: 658px ;height: 376px;" src="@/assets/image/helpAndDownload/help-banner-bg.png" alt="帮助中心" srcset="">
    </Banner>
    <div class="title">
      热门问题
    </div>
    <div class="main-box">
      <div class="icon-animation-box" @click="$router.push({name:'helpList', query: {type:links[index].id}})" v-for="(item,index) in links.length>4?4:links.length" :key="index">
        <div class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/helpAndDownload/help-icon'+(index+1)+'.png')})`}"></div>
        <div>{{links[index].categoryName}}</div>
      </div>
      <div class="icon-animation-box" @click="$router.push({name:'helpList', query: {type:'5'}})">
        <div class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/helpAndDownload/help-icon5.png')})`}"></div>
        <div>更多</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      links: []
    }
  },
  created () {
    this.$api.getContentcategory({ categoryType: 2 }).then(res => {
      this.links = res.data
    })
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 36px;
  color: #303b50;
  line-height: 48px;
  letter-spacing: 1px;
  margin-bottom: 110px;
  margin-top: 120px;
  text-align: center;
  font-weight: 700;
}
.main-box {
  display: flex;
  margin-bottom: 160px;
  justify-content: space-between;
  & > div {
    width: 240px;
    height: 202px;
    background: #f9fbff;
    box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
    border-radius: 5px;
    border: 4px solid #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    color: #303b50;
    line-height: 31px;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
    .icon {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      margin-bottom: 4px;
    }
  }
}
</style>
